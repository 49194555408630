import React, { useRef, useState, useEffect } from 'react';

import Axios from 'axios';
import { toast } from '@/utils/Toast';
import Select, { components } from 'react-select';
import { selectStyling } from '../Partials/SelectStyles';
import ToastsContainer from '@/Layouts/Partials/ToastsContainer';
import { CARD_ELEMENT_OPTIONS } from '../../../constants/StripeElement';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

export default function PaymentForm({
    updateCard,
    selectedCard,
    label = null,
    formError = false,
}) {
    const stripe = useStripe();
    const elements = useElements();
    const cardSelectorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showNewCardBlock, setShowNewCardBlock] = useState(false);

    const requestPaymentMethods = () => {
        const url = `/api/payment-methods`;

        Axios.get(url)
            .then((response) => {
                if (response.data.length > 0) {
                    setPaymentMethods(response.data);
                }
            })
            .catch((error) => {
                console.log(
                    'There was an error while requesting the payment methods: ',
                    error
                );
            });
    };

    useEffect(() => {
        requestPaymentMethods();
    }, []);

    const handleCardSave = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
        });

        if (error) {
            setLoading(false);
            console.log('Stripe error: ', error);
        } else {
            // send paymentMethod.id to the server
            const response = await Axios.post('/api/save-card', {
                paymentMethodId: paymentMethod.id,
            });

            if (response.status === 200 && response.data.status !== 'error') {
                setShowNewCardBlock(false);

                const newCard = {
                    value: paymentMethod.id,
                    brand: paymentMethod.card.brand,
                    label: '**** ' + paymentMethod.card.last4,
                    className: paymentMethod.card.brand.toLowerCase(),
                };

                setLoading(false);
                updateCard(newCard);
                requestPaymentMethods();
                toast({
                    type: 'success',
                    title: 'Success',
                    text: 'Card successfully saved.',
                });
            } else {
                updateCard([]);
                setLoading(false);
                setShowNewCardBlock(false);
                toast({
                    type: 'error',
                    title: 'Error',
                    text: 'There was an error saving your card. Please try again.',
                });
            }
        }
    };

    const selectCard = (event) => {
        if (event && event.value === 'new_card') {
            setShowNewCardBlock(true);
        } else {
            setShowNewCardBlock(false);
        }

        updateCard(event);
    };

    const cardOptions = Array.isArray(paymentMethods)
        ? paymentMethods.map((card) => {
              return {
                  value: card.id,
                  brand: card.card.brand,
                  label: '**** ' + card.card.last4,
                  className: card.card.brand.toLowerCase(),
              };
          })
        : [];

    // TODO - Fix this return value in future to return a false value instead of a valid string
    const newCardOption = {
        brand: false,
        className: '',
        value: 'new_card',
        label: 'Add new card',
    };

    cardOptions.unshift(newCardOption);

    return (
        <>
            <div className="form-group">
                <div className="form-item">
                    <label htmlFor="card-details">
                        {label ?? 'Leave your card details (optional)'}
                    </label>

                    <Select
                        isMulti={false}
                        name="card_filter"
                        isClearable={true}
                        isSearchable={false}
                        options={cardOptions}
                        styles={selectStyling}
                        className={`multi-select ${
                            formError
                                ? 'is-invalid [&_.multi-select__control]:!border-red-500'
                                : ''
                        }`}
                        classNamePrefix="multi-select"
                        aria-labelledby="Card details"
                        onChange={(event) => selectCard(event)}
                        menuPortalTarget={cardSelectorRef.current}
                        value={cardOptions.find(
                            (option) => option.value === selectedCard
                        )}
                        components={{
                            SingleValue: ({ children, ...props }) => {
                                const brandvalue = props.data.brand;
                                const optionValue = props.data.value;
                                const optionClassName = props.data.className;

                                return (
                                    <components.Option {...props}>
                                        <div>
                                            <span className="d-flex align-items-center font-dm-sans">
                                                {optionValue === 'new_card' ? (
                                                    <i
                                                        className="yh-new-icon-Plus"
                                                        style={{
                                                            fontSize: '20px',
                                                            marginRight: '8px',
                                                            fontWeight: '500',
                                                        }}></i>
                                                ) : (
                                                    ''
                                                )}
                                                {brandvalue ? (
                                                    <span
                                                        className={`brand-icons ${optionClassName}`}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                                {children}
                                            </span>
                                        </div>
                                    </components.Option>
                                );
                            },
                            Placeholder: ({ children, ...props }) => {
                                return (
                                    <components.Placeholder {...props}>
                                        <div>
                                            <span
                                                style={{
                                                    padding: '10px 16px',
                                                }}>
                                                Select card
                                            </span>
                                        </div>
                                    </components.Placeholder>
                                );
                            },
                            Option: ({ children, ...props }) => {
                                const brandvalue = props.data.brand;
                                const optionValue = props.data.value;
                                const optionClassName = props.data.className;

                                return (
                                    <components.Option {...props}>
                                        <div>
                                            <span className="d-flex align-items-center font-dm-sans">
                                                {optionValue === 'new_card' ? (
                                                    <i
                                                        className="yh-new-icon-Plus"
                                                        style={{
                                                            fontSize: '20px',
                                                            marginRight: '8px',
                                                            fontWeight: '500',
                                                        }}></i>
                                                ) : (
                                                    ''
                                                )}
                                                {brandvalue ? (
                                                    <span
                                                        className={`brand-icons ${optionClassName}`}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                                {children}
                                            </span>
                                        </div>
                                    </components.Option>
                                );
                            },
                            IndicatorSeparator: () => null,
                        }}
                    />

                    {showNewCardBlock && (
                        <form onSubmit={handleCardSave} className="new-card">
                            <div style={{ boxSizing: 'border-box' }}>
                                <label htmlFor="card-details">
                                    Enter a new card
                                </label>
                                <div
                                    style={{
                                        display: 'flex',
                                        border: '1px solid #D0D5DD',
                                        borderRadius: '4px',
                                    }}>
                                    <CardElement
                                        options={CARD_ELEMENT_OPTIONS}
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-dark"
                                    disabled={loading}>
                                    {loading ? 'Loading...' : 'Save Card'}
                                </button>
                            </div>
                        </form>
                    )}
                </div>

                <ToastsContainer />
            </div>
        </>
    );
}
